<template>
    <div class="aboutUsDiv layui-clear">
      <div class="div1">
        <div class="text">
          <h1 class="common-text">
            {{ listItem.title }}
          </h1>
          <div class="center-intro" v-for="shortInfo in listItem.intro" :key="shortInfo">
            <p><b>{{ shortInfo.title }}</b></p>
            <p style="white-space: pre-wrap;">
              {{ shortInfo.info}}
            </p>
          </div>

          <div class="ac-btn"  @click="itemClick">
            <div class="ac-btn-txt">
              {{title}}
            </div>
            <div class="ac-arr">
              <i class="layui-icon layui-icon-right"></i>
            </div>
          </div>
        </div>

      </div>
      <div class="div2">
       <img :src="listItem.image">
      </div>
    </div>
</template>

<script>
export default {
  name: "serviceItem",
  props: {
    listItem: {
      type:Object, //返回对象
      default() {
        return {}
      }
    },
    title:{}
  },
  methods:{
    itemClick() {
      this.$router.push('/serviceMore/' + this.listItem.id)//传递id
      }
  }
}
</script>

<style scoped>
.aboutUsDiv{
  position: relative;
  padding: 50px;
  background: #fff;
  margin-bottom: 50px;
  min-height: 540px;
}
.aboutUsDiv>div{width: 50%;float: left;}

.aboutUsDiv .div2{
  color: #221615;
  padding: 0 50px;
  position: absolute;
  top: 50%;
  width: 50%;
  transform: translate(0,-50%);
  box-sizing: border-box;
}

.aboutUsBigDiv .aboutUsDiv:nth-child(odd) .div2 {
  right: 0;

}
.aboutUsBigDiv .aboutUsDiv:nth-child(even) .div2 {
  left: 0;

}

.aboutUsBigDiv .aboutUsDiv:nth-child(even) .div1 {
  float: right;
}


.center-intro{
  padding-top: 20px;
}

.center-intro p{
  font-size: 17px;
}

.center-intro p b{
  font-size: 18px;
}

.ac-btn{
  display: flex;
  margin-top: 20px;
  align-items: center;
  cursor: pointer;
}

.ac-btn-txt{
  font-size: 22px;

}

.ac-arr{
  width: 30px;
  height: 30px;
  background: rgba(247, 1, 50, 0.4);
  border-radius: 22px;
  margin-left: 10px;
}

.ac-arr i{
  display: block;
  text-align: center;
  line-height: 30px;
  color: #fff;
}




@media only screen and (min-width: 0) and (max-width:991px){

  .aboutUsBg .text, .aboutUsBg>div:last-child{float: none;width: 100%;padding: 5px 0;}

  .aboutUsBg .text,.aboutUsDiv .text>div{line-height: 1.4;}
  .aboutUsDiv .div2{padding: 15px 0 30px;position: initial;transform:initial;width: 100%;}
  .aboutUsDiv .text .tit{margin-bottom: 10px;}
  .aboutUsDiv>div{display: block;width: 100%;}
  .aboutUsDiv{
    padding: 20px;
    margin-bottom: 20px;
  }

  .aboutUsDiv .text[data-v-1b0ebaeb] {
    padding: 0 0 20px;

  }

}

</style>