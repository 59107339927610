<template>
  <div class="container padding-50">
    <div class="aboutUsBigDiv">

     <service-item v-for="item in list" :key="item" :list-item="item" :title="title" ></service-item>

    </div>
 </div>
</template>

<script>
import serviceItem from "@/components/content/service/serviceItem";
export default {
  name: "serviceList",
  components: {
    serviceItem
  },
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    },
    title:{},
  }
}
</script>

<style scoped>

</style>