<template>
  <div>
    <!--  -->
    <page-banner>
      <template #banner-txt>
        服務範疇
      </template>
      <template #banner-img>
        <img v-lazy="banner" class="img-center">
      </template>
    </page-banner>

    <!--  -->
    <div class="level-two tab-control">
      <div class="container"  style="padding-left: 0px;">
        <div class="level-two-container">
          <div class="tab-control" >

            <div class="tab-control-item">
              <router-link to="/elder">
                <span>長者服務</span>
                <span class="line"></span>
              </router-link>
            </div>


            <div class="tab-control-item">
              <router-link to="/volunteer">
                <span>義工服務</span>
                <span class="line"></span>
              </router-link>
            </div>

            <div class="tab-control-item active">
              <router-link to="/educate">
                <span>教育服務</span>
                <span class="line"></span>
              </router-link>
            </div>

          </div>
        </div>
      </div>
    </div>


    <service-list :list="list" :title="title">

    </service-list>

  </div>
</template>

<script>
import pageBanner from "@/components/PageBanner";
import ServiceList from "@/components/content/service/serviceList";
import {getServeList} from "@/network/home";
export default {
  name: "Educate",
  components:{
    pageBanner,
    ServiceList
  },
  data(){
    return{
      banner:{},
      list:[],
      title:'服務簡介',
    }
  },
  created() {
    this.getServeList()
  },
  methods:{
    getServeList(){
      getServeList().then(res =>{

        this.banner = res.data.data.banner;
        this.list = res.data.data.educational_services;

      })

    }
  }
}
</script>

<style scoped>

</style>